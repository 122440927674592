<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>
        Siete Mares Nadadores
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-card
          elevation="2"
          class="mx-auto"
        >
          <v-card-title class="pa-0">
            <v-toolbar color="accent" dark>
              <v-toolbar-title>
                Cambiar contraseña
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>

            <v-alert
              color="warning"
              border="left"
              prominent
              class="mt-5"
            >
              La contraseña debe ser de al menos <strong>8 caracteres, tener al menos una mayúscula, un número y un caracter especial.</strong>
            </v-alert>

            <validation-observer
              ref="observer"
              v-slot="{ validate, reset }"
            >
              <v-form>
                <v-list
                  three-line
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ labels.newPassword }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="labels.password"
                          :rules="passwordRules"
                          vid="password"
                        >
                          <v-text-field
                            v-model="item.password"
                            :placeholder="labels.password"
                            :error-messages="errors"
                            required
                            type="password"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ labels.confirmPassword }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="labels.confirmPassword"
                          rules="confirmed:password"
                        >
                          <v-text-field
                            v-model="item.passwordConfirmation"
                            :placeholder="labels.confirmPassword"
                            :error-messages="errors"
                            required
                            type="password"
                          />
                        </validation-provider>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-row justify="center">
                          <v-col cols="4">
                            <v-btn
                              color="primary"
                              @click="changePassword"
                            >
                              Cambiar contraseña
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'Public',
    data () {
      return {
        item : {
          password: '',
          passwordConfirmation: '',
          code: ''
        },
        labels: {
          password: 'Contraseña',
          newPassword: 'Nueva contraseña',
          confirmPassword: 'Confirmar contraseña'
        },
        passwordRules: { required: true, min: 8, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }
      }
    },
    mounted () {
      console.log(this.$route.query.code)
    },
    methods: {
      changePassword() {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()
            this.item.code = this.$route.query.code
            await this.$http.post(route('auth/reset-password'), this.item).then(response => {
              if (response.body.jwt && response.body.jwt != '') {
                this.successMessage("Se ha cambiado la contraseña correctamente, ahora puede iniciar sesión en la aplicación.", () => {
                  window.location.href = "https://sietemaresnadadores.mx/"
                })
              } else {
                this.processError("Ha ocurrido un error al modificar la contraseña")
              }
            },
              error => this.processError(error)
            )

            this.toggleLoader()
          })
      }
    }
  }
</script>

<style scoped>

</style>
